import * as React from "react";
import { createRoot } from "react-dom/client";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import App from "./components/App";
import { refreshFormulaData } from "../../helpers";


const rootElement: HTMLElement | null = document.getElementById("container");
if (rootElement !== null) {
  const root = createRoot(rootElement);

  Office.onReady(() => {
    root.render(
      <FluentProvider theme={webLightTheme}>
        <App />
      </FluentProvider>
    );

    setInterval(refreshFormulaData, 60000); // timer for autoupdate cells value
  });
}


