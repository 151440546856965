import * as React from "react";
import { Button, Field, Input, Subtitle1, makeStyles } from "@fluentui/react-components";
import SettKeyCard from "./SettKeyCard";
import DialogForm from "./DialogForm";
import { newUUID, ApiKeyInfo, DialogInfo } from "../../../helpers";


interface FieldInfo {
  current: string;
  state:   "none" | "error" | "warning" | "success" | undefined;
}

const useStyles = makeStyles({
  root: {
    flexDirection:  "column",    
    display:        "flex",  
    paddingLeft:    "10px",
    paddingRight:   "10px",   
  },
  title: {
    marginBottom:   "7px",
  },
  button_add_new: {
    width:          "100%",
    marginBottom:   "30px",
  },
  saving_dialog: {
    flexDirection:  "column",    
    display:        "flex",  
  },
  dialog_field: {
    marginBottom:   "15px",
  },
  dialog_save: {
    width:          "100%",
    marginBottom:   "10px",
  },
  dialog_add: {
    width:          "100%",
    marginBottom:   "10px",
  },
  dialog_cancel: {
    width:          "100%",
    marginBottom:   "15px",
  },

});


const TabSettings = () => {
  const styles = useStyles();

  const [showDialogDouble, setShowDialogDouble] = React.useState<DialogInfo>({show: false, text: ""}); // dialog double  
  const [showDialogDelete, setShowDialogDelete] = React.useState<DialogInfo>({show: false, text: ""}); // dialog delete 

  const [savingState, setSavingState] = React.useState<"none" | "add" | "edit">("none");          // window state
  const [apiKeyList, setApiKeyList] = React.useState<ApiKeyInfo[]>([]);                           // apiKey List
  const [currentKey, setCurrentKey] = React.useState<ApiKeyInfo>({id: "", name: "", value: ""});  // current key

  const [keyName,  setKeyName] =  React.useState<FieldInfo>({current: "", state: "none"}); // fields info
  const [keyValue, setKeyValue] = React.useState<FieldInfo>({current: "", state: "none"}); 


  React.useEffect(() => {
    const getStartData = async () => {
      const json = localStorage.getItem("apiKeyList"); // load apiKey List from storage
      const keys: ApiKeyInfo[] = json ? JSON.parse(json!) : [];   
      setApiKeyList(keys);
    };

    getStartData(); // get Start Data
  }, []); 


  const ValidateFields = () : boolean => { // validate fields
    let valid = true; 

    if(keyName.current.trim() == "" || keyName.current.trim().length > 100){ // validate fields
      setKeyName({...keyName, state: "error"});
      valid = false;
    }else{
      setKeyName({...keyName, state: "none"});
    }

    if(!/^[a-zA-Z0-9]{30,40}$/gm.test(keyValue.current)){ 
      setKeyValue({...keyValue, state: "error"});
      valid = false;
    }else{
      setKeyValue({...keyValue, state: "none"});
    }

    return valid;
  };


  const onButtonAddNewClick = () => { // button - add new api key
    setKeyName({current: "", state: "none"});
    setKeyValue({current: "", state: "none"});
    setCurrentKey({id: "", name: "", value: ""});
    setSavingState("add");
  };

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => { // change key name 
    setKeyName({...keyName, current: event.target.value});
  };

  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => { // change key value 
    setKeyValue({...keyValue, current: event.target.value});
  };

  const onButtonAddClick = () => { // button - save new api key
    if(!ValidateFields()){ return; } 

    const doubleKey = apiKeyList.filter(key => key.value == keyValue.current); // check on doubles

    if(doubleKey.length > 0){
      setKeyValue({...keyValue, state: "error"});
      setShowDialogDouble({show: true, text: doubleKey[0].name});
      return;
    }

    let keys: ApiKeyInfo[] = [...apiKeyList, { id: newUUID(), name: keyName.current, value: keyValue.current }]; // add new api key
    localStorage.setItem("apiKeyList", JSON.stringify(keys)); // save
    setApiKeyList(keys);
    setSavingState("none");    
  };

  const handleEditKey = (id: string) => { // edit api key
    setKeyName({...keyName, state: "none"});
    setKeyValue({...keyValue, state: "none"});

    const key = apiKeyList.find(key => key.id == id);
    if(!key){ return; }

    setCurrentKey(key);
    setKeyName({current: key.name, state: "none"});
    setKeyValue({current: key.value, state: "none"});
    setSavingState("edit");
  };

  const onButtonSaveClick = () => { // button - save
    if(!ValidateFields()){ return; } 

    let keys: ApiKeyInfo[] = apiKeyList.map(key =>{
      if(key.id ==  currentKey.id){ 
        key.name =  keyName.current;
        key.value = keyValue.current;
      }
      return key;
    });

    localStorage.setItem("apiKeyList", JSON.stringify(keys)); // save
    setApiKeyList(keys);
    setSavingState("none");
  };

  const onButtonCancelClick = () => { // button - cancel
    setSavingState("none");
  };

  const handleDeleteKey = (id: string) => { // delete api key
    const key = apiKeyList.find(key => key.id == id);
    if(!key){ return; } 

    setSavingState("none");    
    setCurrentKey(key);
    setShowDialogDelete({show: true, text: key.name});
  };

  const handleResultDialog = (result: "ok" | "cancel") => { // result dialog event
    if(result == "ok"){
      let keys: ApiKeyInfo[] = apiKeyList.filter(item => item.id != currentKey.id);
      localStorage.setItem("apiKeyList", JSON.stringify(keys)); // save
      setApiKeyList(keys);
      setSavingState("none");
    }

    setShowDialogDouble({...showDialogDouble, show: false});
    setShowDialogDelete({...showDialogDelete, show: false});
  };


  return (
    <div className={styles.root} role="tabpanel" aria-labelledby="Settings">
      {showDialogDouble.show &&
        <DialogForm 
          type="modal"
          title="Error" 
          content={"You’ve added this key before under name '" + showDialogDouble.text + "', please check addon API keys."} 
          closeName="Close"
          showPrimary={false}
          onResultDialog={handleResultDialog}
        />      
      }

      {showDialogDelete.show &&
        <DialogForm 
          type="modal"
          title="Confirm Deletion" 
          content={"Are you certain you'd like to remove the API key labeled <b>" + showDialogDelete.text + "</b>?"}
          closeName="Cancel"
          showPrimary={true}
          onResultDialog={handleResultDialog}
        />
      }

      <Subtitle1 className={styles.title}>API keys</Subtitle1>

      {savingState == "none" && <Button className={styles.button_add_new} onClick={onButtonAddNewClick}>Add new API key</Button>}

      {savingState != "none" && <div className={styles.saving_dialog}>
        <Field 
          className={styles.dialog_field} 
          label="API key name" 
          validationState={keyName.state} 
          validationMessage='Enter a recognizable name for your API key. Example: "MyApp API Key" or "US Data API Key".' 
          required
        >
          <Input 
            autoFocus 
            value={keyName.current} 
            placeholder="e.g. US data API key" 
            onChange={handleChangeName} 
          />
        </Field>

        <Field 
          className={styles.dialog_field} 
          label="API key value" 
          validationState={keyValue.state} 
          validationMessage="Copy and paste your API key value from Finazon's dashboard." 
          required
        >
          <Input 
            value={keyValue.current} 
            placeholder="e.g. 86651dbe7305852f9abe" 
            onChange={handleChangeValue} 
          />
        </Field>

        {savingState == "add"  && <Button className={styles.dialog_add}  appearance="primary" onClick={onButtonAddClick}>Add</Button>}
        {savingState == "edit" && <Button className={styles.dialog_save} appearance="primary" onClick={onButtonSaveClick}>Save</Button>}
        <Button className={styles.dialog_cancel} appearance="secondary" onClick={onButtonCancelClick}>Cancel</Button>
      </div>}

      {apiKeyList.map((key, index) => (
        <SettKeyCard 
          key={index} 
          id={key.id} 
          name={key.name} 
          value={key.value} 
          is_last={index != apiKeyList.length - 1} 
          onEditKey={handleEditKey} 
          onDeleteKey={handleDeleteKey} 
        />
      ))}
    </div>
  );
};
export default TabSettings;


