import * as React from "react";
import { Body1, Subtitle1, makeStyles } from "@fluentui/react-components";
import { ArrowSortRegular, BracesRegular, CommentRegular, GlobeRegular } from "@fluentui/react-icons";
import AboutInfoCard from "./AboutInfoCard";


const useStyles = makeStyles({
  root: {
    flexDirection:  "column",    
    display:        "flex",  
    paddingLeft:    "10px",
    paddingRight:   "10px",    
  },
  title: {
    marginBottom:   "7px",
  },
  text: {
    marginBottom:   "7px",
  },

});

const TabAbout = () => {
  const styles = useStyles();

  const cards = [
    {
      icon:       <GlobeRegular style={{fontSize: "32px"}} />,
      title:      "Dashboard",
      link_text:  "Check",
      link_url:   "https://finazon.io/account/home",
    },
    {
      icon:       <CommentRegular style={{fontSize: "32px"}} />,
      title:      "Support",
      link_text:  "Explore",
      link_url:   "https://support.finazon.io",
    },
    {
      icon:       <ArrowSortRegular style={{fontSize: "32px"}} />,
      title:      "Add-in docs",
      link_text:  "Explore",
      link_url:   "https://support.finazon.io/en/articles/8195161-google-sheets-add-on-docs",
    }, 
    {
      icon:       <BracesRegular style={{fontSize: "32px"}} />,
      title:      "API docs",
      link_text:  "Explore",
      link_url:   "https://finazon.io/docs",
    }, 
  ];


  return (
    <div className={styles.root} role="tabpanel" aria-labelledby="About">
      <Subtitle1 className={styles.title}>About us</Subtitle1>

      <Body1 className={styles.text}>
        Finazon is a marketplace that provides financial datasets from all over the world covering various asset classes, such as stocks, forex, crypto, and more. Data is sourced directly from exchanges, and market makers, guaranteeing institutional-grade quality.
      </Body1>

      <Body1 className={styles.text}>
        This add-on tends to cover most datasets and features; however, it can be limiting. For the full potential of the service, use our API.
      </Body1>

      <Subtitle1 className={styles.title}>Resources</Subtitle1>

      {cards.map((card, index) => (
        <AboutInfoCard key={index} icon={card.icon} title={card.title} link_text={card.link_text} link_url={card.link_url}/>
      ))}

    </div>
  );
};
export default TabAbout;


