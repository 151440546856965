import * as React from "react";
import { Button, Divider, Link, makeStyles } from "@fluentui/react-components";
import { TabPanes } from "./TabPanes";
import { createScript } from "../../../codeGenerator";


const useStyles = makeStyles({
  root: {
    minHeight:     "100vh",
    maxWidth:      "370px",
  },
  footer: {
    flexDirection: "column",    
    display:       "flex",  
    alignItems:    "center",
    paddingTop:    "10px",
    paddingBottom: "10px",
  },
  footer_link: {
    marginBottom:  "5px",
  },
  
});


const App = () => {
  const styles = useStyles();

  const onButtonGenerareClick = () => { // button - Generare code
    createScript(); // create code for 'functions.ts' file
  };


  return (
    <div className={styles.root}>
      <TabPanes />

      <Divider inset />

      <div className={styles.footer}>
        <Link className={styles.footer_link} href="https://finazon.io/" target="_blank">
          Finazon LLC
        </Link>

        <Link className={styles.footer_link} href="https://finazon.io/assets/files/Finazon_Terms_of_Service.pdf" target="_blank">
          Terms of use
        </Link>

        <Link className={styles.footer_link} href="https://finazon.io/assets/files/Privacy_Policy.pdf" target="_blank">
          Privacy policy
        </Link>

        {/* buttons for debug */}
        {false &&
          <Button onClick={onButtonGenerareClick} >Generare CF code</Button>             
        }
      </div>
    </div>
  );
};
export default App;


