// 2024 - support@basicsoft.ru
// code generator for file 'functions.ts'


import { sendRequest, pathToFunctionName, sortEndpointParameters } from "./helpers";


// create code for 'functions.ts' file
export async function createScript() {
  const result: any = await sendRequest('/doc/swagger/swagger.json');

  let metadata: any = {
    functions: [],
  };

  let script: string = "// generated: " + new Date() + "\n";
  script += "// code for 'functions.ts' file\n";
  script += "// user functions for addin\n\n\n";
  script += "import { saveCellAddress, sendRequest, getDataToInsert } from \"../../helpers\";\n\n\n";

  for(let key in result.paths) { // each endpoint
      const path = result.paths[key];
      let funcName: string = pathToFunctionName(key);

      let func: any = {
        description:  path.get.description,
        id:           funcName,
        name:         funcName,
        parameters:   [
          {
            description:  "API key",
            name:         "apikey",
            type:         "string",    
            optional:     false,            
            dimensionality: "scalar",     
          },
          {
            description:  "Update frequency",
            name:         "update_frequency",
            type:         "string",   
            optional:     false,              
            dimensionality: "scalar",   
          },
          {
            description:  "Toggle to display or hide data titles",
            name:         "display_titles",
            type:         "boolean",     
            optional:     false,            
            dimensionality: "scalar",       
          },     
        ],
        options: {
          requiresAddress:            true,
          requiresParameterAddresses: true,
          volatile:                   true,
        },
        result: {
          dimensionality: "matrix",
          type:           "any",
        }
      };

      script += "/**\n";
      script += " * " + path.get.description + "\n";
      script += " * @param {string} apikey API key\n";
      script += " * @param {string} update_frequency Update frequency\n";
      script += " * @param {boolean} display_titles Toggle to display or hide data titles\n";

      const params: any[] = [
        {name: "apikey", type: "string", required: true}, 
        {name: "update_frequency", type: "string", required: true}, 
        {name: "display_titles", type: "boolean", required: true}, 
      ];

      if(path.get.parameters) {
        let parameters: any = [...path.get.parameters];
        parameters = sortEndpointParameters(parameters); // sort endpoint parameters 

        parameters.forEach((param: any) => {
            let type: string = "";

            switch(param.type){
              case "string":
                type = "string";
                break;          
              case "integer":
                type = "number";
                break;
              case "boolean":
                type = "boolean";
                break;
              default:
                console.log("Err002: Unknown parameter type: '" + param.type + "' for endpoint: " + path);
                type = "any";
                break;
            }

            script += ` * @param {${type}} ${param.required ? "" : "["}${param.name}${param.required ? "" : "]"} ${param.description}\n`;
            params.push({name: param.name, type: type, required: param.required});

            func.parameters.push({
              description:  param.description,
              name:         param.name,
              type:         type,
              optional:     !param.required,              
              dimensionality: "scalar",   
            });
        })
      }
   
      metadata.functions.push(func);

      let args: string[] = params.map(item => item.name + (item.required ? "" : "?") + ": " + item.type);
      args = [...args, "invocation?: any"];

      let paramLines: string[] = params.map(item => item.name + ": " + item.name);

      script += " * @param {CustomFunctions.Invocation} invocation Invocation object.\n";
      script += " * @return " + path.get.summary + "\n";
      script += " * @customfunction\n";
      script += "*/\n";
      script += "async function " + funcName + "(" + args.join(', ') + "): Promise<any[][]> {\n";
      script += "  saveCellAddress(invocation.address, update_frequency);\n";
      script += "  const endpoint: string = '" + key + "';\n";
      script += "  const params: any = {\n    ";
      script += paramLines.join(', \n    ');
      script += "\n  };\n";
      script += "  const response: any = await sendRequest(endpoint, params);\n";
      script += "  const toInsert: any[][] =   getDataToInsert(response, display_titles);\n";
      script += "  return toInsert;\n";
      script += "};\n";
      script += "CustomFunctions.associate(\"" + funcName + "\", " + funcName + ");\n\n\n";
  }

  console.log("functions.ts");    // print code for 'functions.ts'
  console.log(script);

  console.log("functions.json");  // print code for 'functions.json'
  console.log(JSON.stringify(metadata, null, 2));
}


