import * as React from "react";
import { Dropdown, Option, Field, Input, makeStyles, OptionOnSelectData, SelectionEvents, InputOnChangeData } from "@fluentui/react-components";
import { mergeStyles } from '@fluentui/merge-styles';
import { OptionInfo } from "../../../helpers";


export interface DataFieldProps {
  element:    "textbox" | "dropdown";  
  id:         string;
  label?:     string;
  holder?:    string;
  message?:   string;
  optional?:  boolean;    
  disabled?:  boolean;
  visible?:   boolean;
  required?:  boolean;
  autoFocus?: boolean;
  state?:     "none" | "error" | "warning" | "success" | undefined;

  options?:   OptionInfo[];  
  current?:   string[];
  onOptionSelect?: (dropdown_id: string, selectedOptions: string[]) => void;

  type?:      "text" | "number";  
  onInputChange?: (input_id: string, text: string) => void;
}

const useStyles = makeStyles({
  root: {
    paddingBottom:  "15px",
    paddingLeft:    "10px",
    paddingRight:   "10px",
  },
  optional: {
    backgroundColor: "#F5F7FF",
  },
  list: {
    maxHeight: "320px",
  },

});


const DataField = (props: DataFieldProps) => {
  const styles = useStyles();

  let root_style = mergeStyles(styles.root)
  if(props.optional){ root_style = mergeStyles(styles.root, styles.optional); }

  const onOptionSelect = (_event: SelectionEvents, data: OptionOnSelectData) => { // change dropdown option 
    if(props.onOptionSelect){ props.onOptionSelect(props.id, data.selectedOptions ?? []); }
  };

  const onInputChange = (_event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => { // change input text
    if(props.onInputChange){ props.onInputChange(props.id, data.value ?? ""); }
  };

  let selValues = "";
  if(props.options){ // get dropdown value string
    selValues = props.options
      .filter(item => props.current?.includes(item.key))
      .map(item => item.value)
      .join(', '); 
  }


  return (
    <div>
        {props.element == "textbox" && (props.visible ?? true) && 
          <div className={root_style}>
            <Field 
              label={props.label} 
              validationState={props.state ?? "none"}
              validationMessage={props.message ?? ""} 
              required={props.required ?? false} 
            >
              <Input 
                id={props.id} 
                type={props.type}
                placeholder={props.holder} 
                autoFocus={props.autoFocus ?? false}
                onChange={onInputChange}
              />
            </Field>      
          </div>
        }

        {props.element == "dropdown" && (props.visible ?? true) &&   
          <div className={root_style}>
            <Field 
              label={props.label} 
              validationState={props.state ?? "none"}
              validationMessage={props.message ?? ""} 
              required={props.required ?? false} 
            >
              <Dropdown 
                id={props.id} 
                placeholder={props.holder}  
                autoFocus={props.autoFocus ?? false}        
                selectedOptions={props.current ?? []}
                value={selValues}  
                disabled={props.disabled ?? false} 
                onOptionSelect={onOptionSelect} 
              >
                <div className={styles.list}>
                  {props.options && props.options.map((option: OptionInfo) => (
                    <Option key={option.key} value={option.key}>{option.value}</Option>
                  ))}
                </div>
              </Dropdown>
            </Field>    
          </div>
        }
    </div>
  );
};
export default DataField;


