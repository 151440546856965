import * as React from "react";
import { Button, Dialog, DialogSurface, Divider, Link, Spinner, makeStyles } from "@fluentui/react-components";
import DataField from "./DataField";
import { DataFieldProps } from './DataField';
import DialogForm from "./DialogForm";
import { sendRequest, pathToDataName, pathToFunctionName, sortEndpointParameters, UpdateFreq, ExcludeEndpoints, DialogInfo, OptionInfo, ApiKeyInfo, FormulaPrefix } from "../../../helpers";


const useStyles = makeStyles({
  root: {

  },
  div_optional: {
    paddingLeft:  "10px",
    paddingRight: "10px",  
  },
  show_opt: {
    marginBottom: "10px",
  },
  div_insert: {
    paddingLeft:  "10px",
    paddingRight: "10px",    
  },
  div_insert_hold: {
    paddingLeft:  "10px",
    position:     "fixed",
    bottom:       "0rem",
    paddingRight: "20px", 
    zIndex:       9999,
    width:        "calc(100vw - 37px)",
    backgroundColor: "#F5F7FF",    
  },
  btn_insert: {
    width:        "100%",
    marginTop:    "15px",
    marginBottom: "15px",
  },
  spinner: {
    width:        "75%",
  },

});


const TabData = () => {
  const styles = useStyles();

  const insertLine = React.useRef<HTMLDivElement>(null);
  const divInsert =  React.useRef<HTMLDivElement>(null);

  const [showDialogError, setShowDialogError] = React.useState<DialogInfo>({show: false, text: ""}); // dialog forms
  const [showSpinner, setShowSpinner] = React.useState<boolean>(false); // spinner

  const [showOptional, setShowOptional] = React.useState<DialogInfo>({show: false, text: "Show optional parameters"}); // show optional

  const [swaggerRaw, setSwaggerRaw] = React.useState<any>({});          // raw swagger 
  const [apiKeysRaw, setApiKeysRaw] = React.useState<ApiKeyInfo[]>([]); // raw apikeys 

  const [selApiKey, setSelApiKey] = React.useState<DataFieldProps>({element: "dropdown", id: "selApiKey", options: [], current: []}); // base fields descriptors
  const [selData, setSelData] = React.useState<DataFieldProps>({element: "dropdown", id: "selData", options: [], current: []});
  const [selUpdateFreq, setSelUpdateFreq] = React.useState<DataFieldProps>({element: "dropdown", id: "selUpdateFreq", options: [], current: []});
  const [selDataset, setSelDataset] = React.useState<DataFieldProps>({element: "dropdown", id: "selDataset", options: [], current: []});
  const [selFields, setSelFields] = React.useState<DataFieldProps[]>([]);     // other fields descriptors  
  const [visibleDataset, setVisibleDataset] = React.useState<boolean>(false); // visible Dataset field

  const [insertHoldOn, setInsertHoldOn] = React.useState<boolean>(false); // hold on for Insert button


  React.useEffect(() => {
    var value = localStorage.getItem("apiKeyList") ?? "[]"; // load apiKeys from storage
    const keys: ApiKeyInfo[] = JSON.parse(value);
    setApiKeysRaw(keys);

    var value = localStorage.getItem('selApiKey') ?? "[]";  // selected API key field 
    var opt_api: OptionInfo[] = keys.map((key: any) => { return {key: key.id, value: key.name}; }); // transform    
    let curr_api = JSON.parse(value);
    if(curr_api.length == 0 && opt_api.length > 0){ curr_api = [opt_api[0].key]; }
    setSelApiKey({...selApiKey, options: opt_api, current: curr_api});
  
    var value = localStorage.getItem('selUpdateFreq') ?? "[]"; // selected Update Freq field
    let curr_freq = JSON.parse(value);
    if(curr_freq.length == 0 && UpdateFreq.length > 0){ curr_freq = [UpdateFreq[0].key]; }
    setSelUpdateFreq({...selUpdateFreq, options: UpdateFreq, current: curr_freq});

    if(opt_api.length > 0){ // have keys
      setShowSpinner(true);

      sendRequest("/doc/swagger/swagger.json") // send Get Request
        .then(async swagger => {
          setShowSpinner(false);

          if(swagger.error){ // show error dialog
            let message: string = swagger.error.message
            if(swagger.error.more_info){ message += ' <a href="' + swagger.error.more_info + '" target="_blank">More info...</a>'; }
            setShowDialogError({show: true, text: message}); 
            return; 
          }

          delete swagger.info; // delete unused objects
          delete swagger.definitions;
          delete swagger.responses;       

          if(swagger.paths){
            for(var key in swagger.paths){ // for each endpoint
              if(ExcludeEndpoints.includes(key)){ delete swagger.paths[key]; continue; }
              swagger.paths[key].formula_name = pathToDataName(key); // generate formula names   
            }    
          }

          setSwaggerRaw(swagger);

          var value = localStorage.getItem('selData') ?? "[]";  // selected Data field
          var opt_data: OptionInfo[] = []; 
          for(var key in swagger.paths){ opt_data.push({key: key, value: swagger.paths[key].formula_name}); } // fill data names
          let curr_data = JSON.parse(value);     
          if(curr_data.length == 0 && opt_data.length > 0){ curr_data = [opt_data[0].key]; }
          setSelData({...selData, options: opt_data, current: curr_data});

          fillParamFields(swagger, curr_data); // fill parameters fields  

          await requestDatasets(keys, curr_api); // request dataset 

        })
        .catch(error => {
          setShowSpinner(false);
          setShowDialogError({show: true, text: `Request error: ${error}`}); // show error dialog

        });     

    } 

    window.addEventListener('resize', handleResizeOrScroll); // add Listeners
    window.addEventListener('scroll', handleResizeOrScroll);

    return () => {
      window.removeEventListener('resize', handleResizeOrScroll); // remove Listeners
      window.removeEventListener('scroll', handleResizeOrScroll);
    };

  }, []); 


  React.useEffect(() => {  // hold Insert button on bottom
    if (!insertLine.current || !divInsert.current) { return; }

    const lineRect = insertLine.current.getBoundingClientRect();
    const divRect =  divInsert.current.getBoundingClientRect();

    const needHold: boolean =  (lineRect.top > window.innerHeight - divRect.height);
    setInsertHoldOn(needHold);

  }, [showOptional, selFields]);


  const fillParamFields = (swagger: any, curr_data: string[]) => { // fill parameters fields
    setVisibleDataset(false);
    setSelFields([]);

    if(!curr_data || curr_data.length == 0){ return; }

    const path = swagger.paths[curr_data[0]];
    if(!path || !path.get || !path.get.parameters){ return; }

    let fields: DataFieldProps[] = [];

    fields.push({
      element:  "dropdown",
      id:       "selDisplayTitles",    
      label:    "Display titles",      
      message:  "Toggle to display or hide data titles",
      options:  [{key: "true", value: "true"}, {key: "false", value: "false"}],
      current:  ["true"],
      optional: true,
    }); 

    let parameters: any = [...path.get.parameters];
    parameters = sortEndpointParameters(parameters); // sort endpoint parameters 

    parameters.map((item: any) => { // each parameter
      if(item.name == 'dataset'){ // exception for dataset
        setVisibleDataset(true);
        return; 
      }

      let field: any = {};

      field.label = item.name.replace(/_/g, ' ');
      if(field.label.length > 1){ field.label = field.label.charAt(0).toUpperCase() + field.label.slice(1); }

      if(item.type == 'boolean'){ item.enum = ["true", "false"]; }

      field.id =   item.name;
      field.optional = !item.required;
      field.message =   item.description;

      switch(item.type){
        case "string":
          field.type = "text";
          break;
        case "integer":
          field.type = "number";
          break;
        default:
          field.type = "text";
          break;       
      }

      if(item.enum){ // use select for parameter   
        field.element = "dropdown";
        field.options = [];
        item.enum.map((value: string) => { field.options.push({key: value, value: value}); });
        if(item.enum.length > 0){ field.current = [item.enum[0]]; } // default value

      }else{        // use input for parameter
        field.element = "textbox";
        field.holder =  `e.g. ${item.example}`;

      }

      fields.push(field); 
    })

    setSelFields(fields); 
  };


  const requestDatasets = async (keys: ApiKeyInfo[], curr_key: string[]) => { // request dataset
    var value = localStorage.getItem('selDataset') ?? "[]"; // selected Dataset field
    let curr_dataset: string[] = JSON.parse(value);

    setSelDataset({...selDataset, options: [], current: curr_dataset});

    if(!curr_key || curr_key.length == 0){ return; }

    let apiKey = keys.find(item => curr_key.includes(item.id));
    if(!apiKey){ return; } 

    setShowSpinner(true);

    sendRequest("/datasets", {apikey: apiKey.value}) // send Get Request
      .then(data => {    
        setShowSpinner(false);

        if(data.error){ // show error dialog
          let message: string = data.error.message
          if(data.error.more_info){ message += ' <a href="' + data.error.more_info + '" target="_blank">More info...</a>'; }
          setShowDialogError({show: true, text: message}); 
          return; 
        } 

        const opt_dataset: OptionInfo[] = data.data.map((item: any) => { return {key: item.code, value: item.name}; }); // transform
        setSelDataset({...selDataset, options: opt_dataset, current: curr_dataset});

      })
      .catch(error => {
        setShowSpinner(false);  
        setShowDialogError({show: true, text: `Request error: ${error}`}); // show error dialog 

      }); 
  };


  const handleResultDialog = (_result: "ok" | "cancel") => { // result dialog event
    setShowDialogError({...showDialogError, show: false});
  };


  const onButtonShowOptClick = () => { // link - Show optional parameters
    if(showOptional.show){
      setShowOptional({show: false, text: "Show optional parameters"});
    }else{
      setShowOptional({show: true, text: "Hide optional parameters"});
    }
  };


  const onOptionSelect = (dropdown_id: string, current: string[]) => { // change dropdown option 
    switch(dropdown_id){ // actions
      case "selApiKey":
        localStorage.setItem("selApiKey", JSON.stringify(current)); // save
        setSelApiKey({...selApiKey, current: current, state: "none"});
        requestDatasets(apiKeysRaw, current); // request dataset
        break;

      case "selData":
        localStorage.setItem("selData", JSON.stringify(current)); // save
        setSelData({...selData, current: current, state: "none"});
        let swagger: any = {...swaggerRaw};
        fillParamFields(swagger, current); // fill parameters fields
        break;

      case "selUpdateFreq":
        localStorage.setItem("selUpdateFreq", JSON.stringify(current)); // save
        setSelUpdateFreq({...selUpdateFreq, current: current, state: "none"});
        break;
     
      case "selDataset":
        localStorage.setItem("selDataset", JSON.stringify(current)); // save
        setSelDataset({...selDataset, current: current, state: "none"});
        break;

      default: // other dropdowns
        let fields: DataFieldProps[] = [...selFields];
        let field = fields.find(item => item.id == dropdown_id);
        if(field){ 
          field.current = current; 
          field.state = "none";
        }  
        setSelFields(fields);
        break;      
    }
  };

  
  const onInputChange = (input_id: string, text: string) => { // change input text
    switch(input_id){ // actions
      default: // other dropdowns
        let fields: DataFieldProps[] = [...selFields];
        let field = fields.find(item => item.id == input_id);
        if(field){ 
          field.current = [text]; 
          field.state = "none";
        }  
        setSelFields(fields);
        break;      
    }
  };


  const handleResizeOrScroll = () => { // hold Insert button on bottom
    if (!insertLine.current || !divInsert.current) { return; }

    const lineRect = insertLine.current.getBoundingClientRect();
    const divRect =  divInsert.current.getBoundingClientRect();

    const needHold: boolean =  (lineRect.top > window.innerHeight - divRect.height);
    setInsertHoldOn(needHold);  
  };


  const onButtonInsertClick = () => { // button - insert
    const supportCustomFunctions: boolean = Office.context.requirements.isSetSupported("CustomFunctionsRuntime", "1.1"); // check support Custom Functions
    if(!supportCustomFunctions){
      setShowDialogError({show: true, text: "The current version of MS Office does not support user functions."}); // show error dialog 
      return;
    }

    let fieldIsValid: boolean = true;
    let formIsValid:  boolean = true;
    let formulaParams: any[] = [];


    let apiKeyVal: string = ""; fieldIsValid = true; // validate field
    const apiKey = apiKeysRaw.find(item => selApiKey.current?.includes(item.id));

    if(apiKey){
      apiKeyVal = apiKey.value;
    }else{
      fieldIsValid = false; 
    } 

    if(!apiKeyVal || apiKeyVal == ""){ fieldIsValid = false; }

    if(fieldIsValid){
      setSelApiKey({...selApiKey, state: "none"});
       formulaParams.push({ param: "apikey", value: '"' + apiKeyVal + '"', value2: apiKeyVal, type: "string" });
    }else{
      setSelApiKey({...selApiKey, state: "error"});
      formIsValid = false;
    }


    let endpoint: string = ""; fieldIsValid = true; // validate field
    endpoint = selData.current && selData.current?.length == 1 ? selData.current[0] : ""; 
    if(!endpoint || endpoint == ""){ fieldIsValid = false; }

    if(fieldIsValid){
      setSelData({...selData, state: "none"});
    }else{
      setSelData({...selData, state: "error"});
      formIsValid = false;
    }


    let freqVal: string = ""; fieldIsValid = true; // validate field
    freqVal = selUpdateFreq.current && selUpdateFreq.current?.length == 1 ? selUpdateFreq.current[0] : ""; 
    if(!freqVal || freqVal == ""){ fieldIsValid = false; }

    if(fieldIsValid){
      setSelUpdateFreq({...selUpdateFreq, state: "none"});
      formulaParams.push({ param: "update_frequency", value: '"' + freqVal + '"', value2: freqVal, type: "string" });
    }else{
      setSelUpdateFreq({...selUpdateFreq, state: "error"});
      formIsValid = false;
    }


    const fields: DataFieldProps[] = [...selFields];
 

    let dispalyVal: boolean = false;
    let field = fields.find(entry => entry.id == "selDisplayTitles");

    if(field){
      dispalyVal = field.current && field.current?.length == 1 ? field.current[0] == "true": false;

      field.state = "none";
      setSelFields(fields);
      formulaParams.push({ param: "display_titles", value: dispalyVal, value2: dispalyVal, type: "boolean" });
    }else{
      fieldIsValid = false; 
    } 


    let swagger: any = {...swaggerRaw};
    let path: any = swagger.paths[endpoint];
    if(!path){ return; }


    let parameters: any = [...path.get.parameters];
    parameters = sortEndpointParameters(parameters); // sort endpoint parameters 
 
    parameters.map((item: any) => { // each parameter
      let field: DataFieldProps | undefined;

      if(item.name == "dataset"){
        field = {...selDataset};
      }else{
        field = fields.find(entry => entry.id == item.name);
      }

      if(!field){ return; }

      let paramName: any = field.id;
      if( paramName == "selDataset"){ paramName = "dataset"; }

      let paramVal:  string | boolean | number | null = field.current ? field.current[0] : "";      
      let paramVal2: string | boolean | number | null = paramVal;

      if(item.required == true && (!paramVal || paramVal == "")){ // validate field
        field.state = "error";
        formIsValid = false; 
      }else{
        field.state = "none";
      }

      if(item.name == "dataset"){ // update UI
        setSelDataset(field);
      }else{
        setSelFields(fields);
      }
      
      switch(item.type){
        case "string":
          if(paramVal != ''){ 
            paramVal = '"' + paramVal + '"';
          }else{
            paramVal =  null; 
            paramVal2 = null;
          }
          break;  
        case 'integer':
          if(paramVal != ''){
            paramVal =  parseInt(paramVal, 10);
            paramVal2 = paramVal;
          }else{
            paramVal = null;
            paramVal = null;
          }
          break;
        case 'boolean':
          paramVal = (paramVal == 'true');
          paramVal2 = paramVal;
          break;
      }

      formulaParams.push({ param: paramName, value: paramVal, value2: paramVal2, type: item.type });
    });

    if(!formIsValid){ return; }


    setShowSpinner(true);

    let params: any = {};
    formulaParams.map((item: any) => { params[item.param] = item.value2; });

    sendRequest(endpoint, params) // check endpoint
      .then(data => {    
        setShowSpinner(false);

        if(data.error){ // show error dialog
          let message: string = data.error.message
          if(data.error.more_info){ message += ' <a href="' + data.error.more_info + '" target="_blank">More info...</a>'; }
          setShowDialogError({show: true, text: message}); 
          return; 
        } 

        Excel.run(async (context) => { 
          let formula: string = FormulaPrefix + "." + pathToFunctionName(endpoint);          
          formulaParams = formulaParams.map((item: any) => item.value );

          const range = context.workbook.getSelectedRange();
          range.formulas = [[ "=" + formula + "(" + formulaParams.join(', ') + ")" ]]; // insert formula to active cell
          await context.sync();

        }).catch(error => {
          setShowDialogError({show: true, text: `Insert error: ${error}`}); // show error dialog 

        });

      })
      .catch(error => {
        setShowSpinner(false);  
        setShowDialogError({show: true, text: `Request error: ${error}`}); // show error dialog 

      }); 

  };


  return (
    <div className={styles.root} role="tabpanel" aria-labelledby="Data">
      {showDialogError.show &&
        <DialogForm 
          type="modal"
          title="Error" 
          content={showDialogError.text} 
          closeName="Close"
          showPrimary={false}
          onResultDialog={handleResultDialog}
        />      
      }

      {showSpinner &&      
        <Dialog defaultOpen={true}>
          <DialogSurface className={styles.spinner} >
            <Spinner autoFocus labelPosition="after" label="Loading..." />
          </DialogSurface>
        </Dialog>      
      }

      <DataField 
        element="dropdown"       
        key="selApiKey" 
        id="selApiKey" 
        label="API key" 
        holder="Please select value" 
        state={selApiKey.state}         
        message={selApiKey.state == "error" ? "Please fill in the required field" : (selApiKey.options && selApiKey.options.length > 0 ? "" : "Add your API keys on the Settings tab")} 
        disabled={selApiKey.options && selApiKey.options.length == 0}
        required={true}
        autoFocus={true}

        options={selApiKey.options} 
        current={selApiKey.current}
        onOptionSelect={onOptionSelect}
      />

      <DataField 
        element="dropdown"       
        key="selData" 
        id="selData" 
        label="Data" 
        holder="Please select value" 
        state={selData.state}   
        message={selData.state == "error" ? "Please fill in the required field" : ""} 
        disabled={selApiKey.options && selApiKey.options.length == 0}
        required={true}

        options={selData.options} 
        current={selData.current}
        onOptionSelect={onOptionSelect}
      />

      <DataField 
        element="dropdown"       
        key="selUpdateFreq" 
        id="selUpdateFreq" 
        label="Update frequency" 
        holder="Please select value" 
        state={selUpdateFreq.state}   
        message={selUpdateFreq.state == "error" ? "Please fill in the required field" : "Data refresh frequency"} 
        disabled={selApiKey.options && selApiKey.options.length == 0}
        required={true}

        options={selUpdateFreq.options} 
        current={selUpdateFreq.current}
        onOptionSelect={onOptionSelect}
      />

      <DataField 
        element="dropdown"       
        key="selDataset" 
        id="selDataset" 
        label="Dataset" 
        holder="Please select value"
        state={selDataset.state}    
        message={selDataset.state == "error" ? "Please fill in the required field" : (selApiKey.options && selApiKey.options.length > 0 ? "Filter by Finazon's dataset code" : "Field will become active when you choose API key")} 
        disabled={selApiKey.options && selApiKey.options.length == 0}
        visible={visibleDataset}    
        required={true}

        options={selDataset.options} 
        current={selDataset.current}
        onOptionSelect={onOptionSelect}
      />

      {/* requered fields */}
      {selFields.filter(field => !field.optional).map(field => (
        <DataField 
          element={field.element}       
          key={field.id} 
          id={field.id}
          label={field.label}
          holder={field.holder}
          state={field.state} 
          message={field.state == "error" ? "Please fill in the required field" : field.message} 
          optional={field.optional}
          required={true}

          options={field.options} 
          current={field.current}
          onOptionSelect={onOptionSelect}

          type={field.type} 
          onInputChange={onInputChange}
        />
      ))}
      
      {selFields.filter(field => field.optional).length > 0 && 
        <div className={styles.div_optional}>
          <Link 
              className={styles.show_opt} 
              href="" 
              onClick={onButtonShowOptClick}
            >
            {showOptional.text}
          </Link>
        </div>
      }    

      {/* optionsl fields */}
      {showOptional.show && selFields.filter(field => field.optional).map(field => (
        <DataField 
          element={field.element}       
          key={field.id} 
          id={field.id}
          label={field.label}
          holder={field.holder}
          state={field.state} 
          message={field.state == "error" ? "Please fill in the required field" : field.message} 
          optional={field.optional}

          options={field.options} 
          current={field.current}
          onOptionSelect={onOptionSelect}

          type={field.type} 
          onInputChange={onInputChange}
        />
      ))} 

      <Divider appearance="subtle" ref={insertLine} />

      <div className={insertHoldOn ? styles.div_insert_hold : styles.div_insert} ref={divInsert}>
          <Button 
            className={styles.btn_insert}  
            appearance="primary" 
            disabled={selApiKey.options && selApiKey.options.length == 0}
            onClick={onButtonInsertClick}
          >
            Insert
          </Button>
      </div>

    </div>
  );
};
export default TabData;


