import * as React from "react";
import { Body1, Body1Strong, Divider, Link, makeStyles } from "@fluentui/react-components";


export interface SettKeyCardProps {
  id:          string;
  name:        string;
  value:       string;
  is_last:     boolean;
  onEditKey:   (id: string) => void;
  onDeleteKey: (id: string) => void;
}

const useStyles = makeStyles({
  root: {
    flexDirection:  "column",    
    display:        "flex",  
  },
  key_name: {
    marginBottom:   "10px",
  },
  key_value: {
    marginBottom:   "10px",
  },
  row: {
    flexDirection:  "row",    
    display:        "flex",  
    marginBottom:   "10px",
  },
  btn_copy: {
    marginLeft:     "0",
  },
  btn_edit: {
    marginLeft:     "auto",
  },
  btn_delete: {
    marginLeft:     "auto",
    color:          "red",
  },
  divider: {
    marginBottom:   "15px",
  },

});


const SettKeyCard = (props: SettKeyCardProps) => {
  const styles = useStyles();
  const [copyLabel, setCopyLabel] = React.useState("Copy");

  const onButtonCopyClick = () => { // click button - copy
    navigator.clipboard.writeText(props.value)
      .then(() => {
        setCopyLabel("Copied");
        setTimeout(() => setCopyLabel("Copy"), 700);
      })
      .catch((error) => {
        console.log("Err001: " + error);
      });
  };

  const onButtonEditClick = () => { // click button - edit
    props.onEditKey(props.id);
  };

  const onButtonDeleteClick = () => { // click button - delete
    props.onDeleteKey(props.id);
  };


  return (
    <div className={styles.root}>
      <Body1Strong className={styles.key_name}>{props.name}</Body1Strong>
      <Body1 className={styles.key_value}>{props.value}</Body1>

      <div className={styles.row}>
        <Link className={styles.btn_copy}   onClick={onButtonCopyClick}   href="">{copyLabel}</Link>
        <Link className={styles.btn_edit}   onClick={onButtonEditClick}   href="">Edit</Link>
        <Link className={styles.btn_delete} onClick={onButtonDeleteClick} href="">Delete</Link>
      </div>

      {props.is_last && <Divider className={styles.divider} />}
    </div>
  );
};
export default SettKeyCard;


