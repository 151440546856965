import * as React from "react";
import { makeStyles, Tab, TabList } from "@fluentui/react-components";
import type { SelectTabData, SelectTabEvent, TabValue } from "@fluentui/react-components";
import TabData from "./TabData";
import TabSettings from "./TabSettings";
import TabAbout from "./TabAbout";


const useStyles = makeStyles({
  root: {
    alignItems:     "flex-start",
    display:        "flex",
    flexDirection:  "column",
    justifyContent: "flex-start",
    paddingTop:     "10px",
    paddingLeft:    "1px",
    paddingRight:   "1px"
  },
  tab_menu: {
    paddingBottom:  "20px",
  },
  tab_cont: {
    width:          "100%",
  },

});


export const TabPanes = () => {
  const styles = useStyles();
  const [selectedValue, setSelectedValue] = React.useState<TabValue>("data");

  const onTabSelect = (_event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
  };


  return (
    <div className={styles.root}>
      <TabList 
        className={styles.tab_menu} 
        selectedValue={selectedValue} 
        onTabSelect={onTabSelect}
      >
        <Tab id="Data"      value="data">Data</Tab>
        <Tab id="Settings"  value="settings">Settings</Tab>
        <Tab id="About"     value="about">About</Tab>
      </TabList>
      
      <div className={styles.tab_cont}>
        {selectedValue === "data" &&     <TabData />}
        {selectedValue === "settings" && <TabSettings />}
        {selectedValue === "about" &&    <TabAbout />}
      </div>
    </div>
  );
};
export default TabPanes;


