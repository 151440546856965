import * as React from "react";
import { Link, Subtitle2, makeStyles } from "@fluentui/react-components";
import { ArrowSortUpRegular } from "@fluentui/react-icons";


interface AboutInfoCardProps {
  icon:       React.JSX.Element;
  title:      string;
  link_text:  string;
  link_url:   string;
}

const useStyles = makeStyles({
  root: {
    backgroundColor:  "#f4f4fb",
    borderBottomLeftRadius:   "12px",
    borderBottomRightRadius:  "12px",
    borderTopLeftRadius:      "12px",
    borderTopRightRadius:     "12px",
    paddingTop:       "16px", 
    paddingRight:     "20px", 
    paddingBottom:    "16px", 
    paddingLeft:      "20px",
    marginBottom:     "16px",
  },
  row: {
    flexDirection:    "row",    
    display:          "flex",  
    alignItems:       "center",
  },
  title: {
    marginLeft:       "7px",
  },
  link: {
    flexDirection:    "row",    
    display:          "flex",  
    alignItems:       "center",
  },
  arrow_icon: {
    marginLeft:       "5px",
    fontSize:         "18px", 
    transform:        "rotate(90deg)",
  },
});


const AboutInfoCard = (props: AboutInfoCardProps) => {
  const styles = useStyles();

  
  return (
    <div className={styles.root}>
      <div className={styles.row}>
        {props.icon}

        <Subtitle2 className={styles.title}>
          {props.title}
        </Subtitle2>
      </div>

      <Link className={styles.link} href={props.link_url} target="_blank">
        {props.link_text}
        <ArrowSortUpRegular className={styles.arrow_icon}/>
      </Link>
    </div>
  );
};
export default AboutInfoCard;


